#checkoutPage {
    background-image: url("../../assets/slate\ background.jpeg");
    min-height:60rem;
}

#checkoutForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#totalToPay {
    color:white;
    letter-spacing: 4px;
    margin-top: 20%;
    width: 100%;
    margin-bottom: 5%;
}

.FormGroupPay {
	margin: 0 15px 20px;
	padding: 20px;
    margin-top: 3%;
    width: 70%;
	border-style: none;
	background-color: #26325a;
	will-change: opacity, transform;
	border-radius: 1rem;
}

.FormRowPay {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
    color: #fff;
	margin-left: 15px;
	border-top: 1px solid #26325a;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

#buttonPay {
	display: block;
	font-size: 20px;
    width: 50%;
	height: 40px;
	margin: 40px 15px 0;
	background-color: #26325a;
	font-family: 'Ubuntu', sans-serif;
    text-transform: lowercase;
	border-radius: 1rem;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
    letter-spacing: 4px;
}

#buttonPay:active {
	background-color: aliceblue;
	transform: scale(0.99);
}

#customerInfo {
	display: flex;
    flex-direction: column;
    align-items: center;
}

#checkoutInfo {
	height:40px;
    width:400px;
    border-radius: 2rem;
    font-family: 'Ubuntu', sans-serif;
    border-color: #26325a;
    letter-spacing: 3px;
    padding-left: 15px; 
}

#emailReciept {
	color:white;
	margin:0px;
	padding-right: 5px;
	letter-spacing:4px;
}

.successful {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	color:white;
	letter-spacing: 4px;
}

#dottie {
	margin-top: 5%;
    margin-left: 35%;
}

#successPayment {
	padding-top: 10%;	
} 

#preparingOrder {
	padding: 2%;
}

#orderReady {
	padding: 5%;
}

#preparingIcon {
    font-size: 200px;
	margin-left: 40%;
}

@keyframes scaling {
	0%, 100%{
		transform:scale(0.2);
	}
	40%{
		transform: scale(1);
	}
	50%{
		transform:scale(1);
	}
	
}

.loadingDots{
	height: 40px;
	width:40px;
	border-radius: 50%;
	transform: scale(0);
	background-color: white;
	animation: scaling 2.5s ease-in-out infinite;
	display:inline-block;
	margin: .5rem;
}



.loadingDots:nth-child(0){
	animation-delay:0s;
}
.loadingDots:nth-child(1){
	animation-delay:0.2s;
}
.loadingDots:nth-child(){
	animation-delay:0.4s;
}
.loadingDots:nth-child(3){
	animation-delay:0.6s;
}
.loadingDots:nth-child(4){
	animation-delay:0.8s;
}
.loadingDots:nth-child(5){
	animation-delay:1s;
}

@media screen and (max-width: 800px){

	#checkoutPage {
		min-height: 40rem;
	}

	#dottie {
		margin-top: 5%;
		margin-left: 25%;
	}

	#preparingIcon {
		font-size: 200px;
		margin-left: 35%;
	}

}

@media screen and (max-width: 530px){

	#checkoutPage {
		min-height: 35rem;
	}

	.FormGroupPay {
		width: 90%;
	}

	#checkoutInfo {
		width: 300px;
	}

	#orderReady {
		padding: 10%;
		text-align: center;
	}

	#dottie {
		margin-top: 5%;
		margin-left: 15%;
	}

	#preparingIcon {
		font-size: 200px;
		margin-left: 20%;
	}

}