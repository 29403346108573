#cart1 {
    text-align: center;
    align-self: center;
    color:#26325a;
    background-image: url("https://res.cloudinary.com/dl0hsgmfc/image/upload/v1631985686/tacotavern/taco-cover-12.jpg");
    background-size:cover;
    background-position: center;
    padding:80px;
    min-height: 60rem;
}

.table{
    background-color: aliceblue;
    border: 2px solid #26325a;
    color: #26325a;
}

#emptyCartBtn {
    background-color:transparent;
    border: none;
    color: #26325a;
    letter-spacing: 2px;
}

#pricetable {
    float: right;
}

.checkoutbtn {
    font-family: 'Ubuntu', sans-serif;
    font: weight 700px;
    position: relative;
    left:20px;
    display: inline-block;
    padding: 15px 15px;
    margin: 40px 0;
    color: #FDFDFD;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    margin-right: 50px;
    border-radius: 1rem;
    border-style: none;
}

.checkoutbtn:hover{
background: #26325A;
color: white;
box-shadow: 0 0 5px #26325A,
            0 0 25px #26325A,
            0 0 50px #26325A,
            0 0 200px #26325A;
-webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

#dishCart {
    text-transform: lowercase;
}

#deleteItemBtn {
    background-color:transparent;
    border-color:transparent;
    color:#26325A;
}

.columnHeader {
    font-size:18px;
    letter-spacing: 3px;
}

@media screen and (max-width:800px) {

    #cart1 {
        padding: 0px;
        padding-top: 15%;
        min-height: 50rem;
    }
}
