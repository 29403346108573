.container {
  max-width: max-content;
  padding-left: 0;
  padding-right: 0;
  
}

.body {
  display: flex;
  flex-direction: wrap;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}

#background1 {
    width: 100%;
    display:flex;
    flex-direction:row;
    height:auto;
    background-color:#d59509;
}

#background2 {
    width: 100%;
    display:flex;
    flex-direction: row;
    height:auto;
    background-color:#7d821e;
    cursor: pointer;
}

#coverimg1 {
  width: 66%;
  height: auto;
}

#coverimg3 {
  display:none;
}

#viewmenu {
    font-size: 50px;
    color:#26325a;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-self: center;
    letter-spacing: 4px;
    margin: 50px;
    border-bottom: 15px dotted #26325a;
}

#placeorder {
    font-size: 50px;
    color:#26325a;
    flex: 1;
    text-align: center;
    align-self: center;
    letter-spacing: 4px;
    margin: 50px;
    border-bottom: 15px dotted #26325a;
}

#coverimg2 {
  width: 66%;
  height: auto;
}

@media screen and (max-width:800px) {
  
  #viewmenu {
    font-size: 40px;
    margin: 40px;
  }

  #placeorder {
    font-size: 40px;
  }

}

@media screen and (max-width:530px) {
  
  #background1 {
    width: 100%;
    display:flex;
    flex-direction:column;
    height:auto;
    background-color:#d59509;
  }

  #background2 {
      width: 100%;
      display:flex;
      flex-direction: column;
      height:auto;
      background-color:#7d821e;
  }

  #coverimg1 {
    width: 100%;
    height: auto;
  }

  #viewmenu {
      font-size: 30px;
      flex: 1;
      margin: 50px;
  }

  #placeorder {
      font-size: 30px;
      flex: 1;
      margin: 40px;
  }

  #coverimg2 {
    display: none;
  }
 
  #coverimg3 {
    display:flex;
    width: 100%;
    height: auto;
  }

}