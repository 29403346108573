#formsignupres {
    text-align: center;
    align-self: center;
    color:#26325a;
    background-image: url("https://res.cloudinary.com/dl0hsgmfc/image/upload/v1631985686/tacotavern/taco-cover-9.jpg");
    background-size:cover;
    background-position: top;
    padding:80px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}



#signupRest {
    margin:0px;
    padding-top: 20px;
    padding-bottom: 40px;
    font-size:50px;
    letter-spacing: 4px;
}


.inputRest {
    height:40px;
    width:400px;
    margin-top:10px;
    border-radius: 2rem;
    font-family: 'Ubuntu', sans-serif;
    border-color: #26325a;
    letter-spacing: 4px;
    padding-left: 15px;
}

.btnloginRest {
    height:auto;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;
    font: weight 700px;
    position: relative;
    left:20px;
    display: inline-block;
    padding: 25px 30px;
    margin: 40px 0;
    color: #fdfdfd;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    margin-right: 50px;
    border-radius: 1rem;
    border-style: none;
}
.btnloginRest:hover{
    background: #26325a;
    color: white;
    box-shadow: 0 0 5px #26325a,
                0 0 25px #26325a,
                0 0 50px #26325a,
                0 0 200px #26325a;
     -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

@media screen and (max-width:530px) {

    .inputRest {
        width:300px;
    }

    #signupRest {
        font-size: 40px;
    }

    .btnloginRest {
        width:300px;
        padding: 15px 20px;
    }
}