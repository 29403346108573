.btnupload {
    height:auto;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;
    font: weight 700px;
    position: relative;
    left:20px;
    display: inline-block;
    padding: 15px 15px;
    margin: 40px 0;
    color: #fdfdfd;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    margin-right: 50px;
    border-radius: 1rem;
    border-style: none;
   
}
.btnupload:hover{
    background: #26325a;
    color: white;
    box-shadow: 0 0 5px #26325a,
                0 0 25px #26325a,
                0 0 50px #26325a,
                0 0 200px #26325a;
     -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

#choosefile {
    text-transform: lowercase;
}
