.chartcontainer {
    padding-top: 5%;
    margin:0px;
    min-height:60rem;
}

#chartHeader {
    color:#25335a;
    padding:3%;
    text-align:center;
}
#chartHeader2 {
    color:#25335a;
    text-align:center;
    font-size: 10px;
}

tspan{
    display: none;
}

@media screen and (max-width:800px) {

    .chartcontainer {
        min-height:40rem;
    }

    .chartcontainer {
        width: 600px;
    }

}

@media screen and (max-width:530px) {

    .chartcontainer {
        min-height:20rem;
    }

    .chartcontainer {
        width: 350px;
    }

}