
#formsignup {
    text-align: center;
    align-self: center;
    color:#26325a;
    background-image: url("../../assets/signup.jpeg");
    background-size:cover;
    background-position: bottom center;
    padding-top: 200px;
    height: 60rem;
}

#signup {
    margin:0px;
    padding-bottom: 20px;
    font-size:60px;
    letter-spacing: 4px;
}

.inputSignup {
    height:40px;
    width:400px;
    margin-top:10px;
    border-radius: 2rem;
    font-family: 'Ubuntu', sans-serif;
    border-color: #26325a;
    letter-spacing: 3px;
    padding-left: 15px;
}

.btnlogin {
    height:auto;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;
    font: weight 700px;
    position: relative;
    left:20px;
    display: inline-block;
    padding: 25px 30px;
    margin: 40px 0;
    color: #fdfdfd;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    margin-right: 50px;
    border-radius: 1rem;
    border-style: none;
}

.btnlogin:hover{
    background: #26325a;
    color: white;
    box-shadow: 0 0 5px #26325a,
                0 0 25px #26325a,
                0 0 50px #26325a,
                0 0 200px #26325a;
     -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

@media screen and (max-width:800px) {

    #formsignup {
        padding-top: 120px;
        height: 40rem;
    }

}


@media screen and (max-width:530px) {

    .inputSignup {
        width: 300px;
    }

    #signup {
        font-size:50px;
    }
    
}