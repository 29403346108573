#menu {
   color:white;
   display:flex;
   padding: 5% 0;
   border-color: white;
   margin-left: 10%;
}

#goToCheckoutBtn {
    font-family: 'Ubuntu', sans-serif;
    font: weight 700px;
    position: fixed;
    left: 75%;
    top: 92%;
    font-size: 25px;
    display: inline-block;
    padding: 25px 30px;
    margin: 40px 0;
    color: #fdfdfd;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    margin-right: 50px;
    border-radius: 1rem;
    border-style: none;
    z-index: 1 ;
}

#goToCheckoutBtn:hover{
    background: #26325a;
    color: white;
    box-shadow: 0 0 5px #26325a,
                0 0 25px #26325a,
                0 0 50px #26325a,
                0 0 200px #26325a;
    -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

#menu-header {
    display:flex;
    justify-content:center;
    align-items:flex-start;
    padding-top: 3%;
    color: white;
    text-transform: lowercase;
    font-size:40px;
}

.border {
    padding: 4rem 8rem;
    display: inline-block;
    border: 3px solid white;
    font-size: 2.5rem;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    letter-spacing: 0.3ch;
    background: white;
    position: relative;
}

.containermenu {
    background-image: url("../../assets/slate\ background.jpeg");
    width: 100%;
}

.item {
    padding:2%;
    font-size:20px;
    text-transform: lowercase;
}

#dish {
    font-size:30px;
    text-transform: lowercase;
}

#addCartBtn {
    font-family: 'Ubuntu', sans-serif;
    font: weight 700px;
    position: relative;
    left:20px;
    display: inline-block;
    padding: 25px 30px;
    margin: 40px 0;
    color: #fdfdfd;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    margin-right: 50px;
    border-radius: 1rem;
    border-style: none;
}

#addCartBtn:hover{
background: #26325a;
color: white;
box-shadow: 0 0 5px #26325a,
            0 0 25px #26325a,
            0 0 50px #26325a,
            0 0 200px #26325a;
-webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

.ui-tooltip {
    font-size: 25px;
    color: white;
    vertical-align: text-top;
    padding-left:5px;
}

.ui-tooltip:hover {
    color: #26325a;
}

.num-likes {
    font-size: 30px;
    vertical-align: middle;
    display: inline-block;
    padding-top: 7px;
}

.btnLike {
    font-family: 'Ubuntu', sans-serif;
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    margin: 10px;
    color: #fdfdfd;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    border-radius: 1rem;
    border-style: none;
   font-size: medium;
}

@media screen and (max-width:800px) {

    #goToCheckoutBtn { 
        left: 65%;
        padding: 15px 20px;
        margin:0px;
        }

}

@media screen and (max-width:530px) {

    #menu-header {
        padding-top: 10%;
    }

    .border {
        padding: 0rem 0rem;
    }

    #addCartBtn {
        position: relative;
        width: 200px;
        left: 16px;
        display: inline-block;
        padding: 15px 20px;
        margin: 40px 0;
        margin-right: 0px; 
    }

    #goToCheckoutBtn {
        left: 22%;
    }
    
}