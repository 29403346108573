
  body {
    font-family: 'Ubuntu', sans-serif; 
  }
  
  #profilepic{
    height: 100px;
    cursor: pointer;
  }

  .header {
    display:flex;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    height:260px;
    background-image: url("../../assets/redback.jpeg");
    background-size: cover;
    border-bottom: 20px solid #25335a;
  }

  #logo {
    position: relative;
    cursor: pointer;
    left: -70px;
  }

  .btnHeader {
    font-family: 'Ubuntu', sans-serif;
    position: relative;
    display: inline-block;
    padding: 25px 30px;
    left: 30%;
    color: #fdfdfd;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    margin-right: 50px;
    border-radius: 1rem;
    border-style: none;
   
}
.btnHeader:hover{
    background: #26325a;
    color: white;
    box-shadow: 0 0 5px #26325a,
                0 0 25px #26325a,
                0 0 50px #26325a,
                0 0 200px #26325a;
     -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

.btnHeaderLogin {
  font-family: 'Ubuntu', sans-serif;
  position: relative;
  display: inline-block;
  padding: 15px 20px;
  margin: 10px;
  color: #fdfdfd;
  background-color:#26325a;
  text-decoration: none;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  border-radius: 1rem;
  border-style: none;
 
}

.btnHeader:hover{
  background: #26325a;
  color: white;
  box-shadow: 0 0 5px #26325a,
              0 0 25px #26325a,
              0 0 50px #26325a,
              0 0 200px #26325a;
   -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

@media screen and (max-width:800px) {
  
  #loggedIn {
    flex-direction: column;
  }

  #profilepic {
    display:none;
  }

  .loggedInBtn {
    width: 200px;
    margin-left: 80px;
  }

}



@media screen and (max-width:530px) {

  .header{
    height:300px;
  }
  
  #logo {
    height: 108px;
    width: auto;
    position: relative;
    top: -4px;
    left: -21px;
  }

  #notLoggedIn {
    position: relative;
    left: -23px;
  }

  .notLoggedInBtn {
    width: 150px;
  }


  .loggedInBtn {
    position: relative;
    left: 12px;
    margin-left: 0px;
    width: 150px;
  }

  #profilepic {
    display:none;
  } 

  #loggedIn {
    position: relative;
    left: -20px;
  } 

}
