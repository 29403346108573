.footer {
  clear: both;
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  width: 100%;
  height:250px;
  background-image: url("../../assets/redback.jpeg");
  background-size: cover;
  bottom: 0;
  left: 0;
  text-align: center;
  border-top: 20px solid #25335a;
}

#whitespaceFooter {
  flex:2;
  text-align: center;
  align-self: center;
}

#address {
  align-self: center;
  margin-left: 3%;
  color:#26325a;
  letter-spacing: 4px;
}

#copyright {
  color: #26325a;
  letter-spacing: 4px;
  font-size: 15px;
  padding-top: 10px;
}

.btnfooter {
  font-family: 'Ubuntu', sans-serif;
  font: weight 700px;
  position: relative;
  display: inline-block;
  padding: 25px 30px;
  margin: 20px 0;
  color: #fdfdfd;
  background-color:#26325a;
  text-decoration: none;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  margin-right: 50px;
  border-radius: 1rem;
  border-style: none;
 
}

.btnfooter:hover{
  background: #26325a;
  color: white;
  box-shadow: 0 0 5px #26325a,
              0 0 25px #26325a,
              0 0 50px #26325a,
              0 0 200px #26325a;
   -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}


@media screen and (max-width:1250px) {
  
  .btnfooter {
    padding: 20px 30px;
    margin: 5px 5px;
  }

  #address {
    font-size:20px;
  }

}

@media screen and (max-width:530px) {

  .footer {
    height: 300px;
  }
    
  .btnfooter {
    padding: 10px 20px;
    margin: 5px;
    position: relative;
    left: -9px;
  }

  #address {
    font-size:20px;
    margin:20px;
  }

  #whitespaceFooter {
   font-size: 20px;
  }

}
