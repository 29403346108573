#error {
    font-family: 'Ubuntu', sans-serif;
    background-image: url("../../assets/4042.jpeg");
    background-size:cover;
    background-position: top;
    color: #26325a;
    font-size:500px;
    margin: 0px;
    padding-bottom: 400px;
    text-align:center;
    min-height: 60rem;
}

@media screen and (max-width:800px) {

    #error {
        font-size:300px;
        padding-bottom: 0px;
        min-height: 40rem;
    }

}

@media screen and (max-width:530px) {
    
    #error {
        font-size: 150px;
        min-height: 30rem;
    }

}