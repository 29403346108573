#profilecontainer {
    text-align: center;
    align-self: center;
    color:#26325a;
    background-image: url("../../assets/food2background.jpeg");
    background-size:cover;
    background-position: top;
    padding:80px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    min-height: 70rem;
}

#profile {
    font-size:60px;
    letter-spacing: 4px;
    padding-bottom: 20px;
}

#formprofile {
    text-align: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.inputProfile {
    height:35px;
    width:400px;
    margin-top:10px;
    border-radius: 2rem;
    font-family: 'Ubuntu', sans-serif;
    border-color: #26325a;
    letter-spacing: 3px;
    padding-left: 15px;
}

.btnprofile {
    height:auto;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;
    font: weight 700px;
    position: relative;
    left:20px;
    display: inline-block;
    padding: 15px 32px;
    margin-top: 20px;
    margin-bottom: 40px;
    color: #fdfdfd;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    margin-right: 50px;
    border-radius: 1rem;
    border-style: none; 
}

.btnprofile:hover{
    background: #26325a;
    color: white;
    box-shadow: 0 0 5px #26325a,
                0 0 25px #26325a,
                0 0 50px #26325a,
                0 0 200px #26325a;
     -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

@media screen and (max-width:530px) {

    #profilecontainer {
        min-height: 0rem;
    }

    .inputProfile {
        width:300px;
        margin-top:10px;
    }

    .btnprofile {
    width: 300px;
    padding: 15px 20px;
    }
    
}