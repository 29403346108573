
#formlogin {
    text-align: center;
    align-self: center;
    color:#26325a;
    background-image: url("https://res.cloudinary.com/dl0hsgmfc/image/upload/v1631985686/tacotavern/taco-cover-5.jpg");
    background-size:cover;
    background-position: center;
    min-height: 60rem;
    padding-top: 200px;
}

#login {
    margin:0px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size:60px;
    letter-spacing: 4px;
}

.inputLogin {
    height:40px;
    width:400px;
    margin-top:10px;
    border-radius: 2rem;
    font-family: 'Ubuntu', sans-serif;
    border-color: #26325a;
    letter-spacing: 3px;
    padding-left: 15px; 
}

.btnLogin {
    height:auto;
    width:300px;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;
    font: weight 700px;
    position: relative;
    left:20px;
    display: inline-block;
    padding: 25px 30px;
    margin: 20px 0;
    color: #fdfdfd;
    background-color:#26325a;
    text-decoration: none;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    margin-right: 50px;
    border-radius: 1rem;
    border-style: none; 
}

.btnLogin:hover{
    background: #26325a;
    color: white;
    box-shadow: 0 0 5px #26325a,
                0 0 25px #26325a,
                0 0 50px #26325a,
                0 0 200px #26325a;
     -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}

#loggedin {
    letter-spacing: 4px;
    padding-top: 10px;
}

@media screen and (max-width:530px) {

    #formlogin {
        min-height: 40rem;
        padding-top:100px;
    }

    .inputLogin {
    width: 300px;
    }

    .btnLogin {
        padding: 15px 30px;
    }
}

